export const customSelectStyles = {
  control: (provided) => ({
    ...provided,
    marginTop: 10,
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 8,
    borderRadius: 5,
    border: "1px solid #D3D3D3",
    boxShadow: "none",
    "&:hover": {
      border: "1px solid #5C6165"
    },
    cursor: "pointer"
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: 14,
    fontWeight: 500,
    color: "#4A5567"
  }),
  indicatorSeparator: () => ({
    display: "none"
  }),
  option: (provided) => ({
    ...provided,
    cursor: "pointer",
    color: "#4A5567",
    backgroundColor: "#FFF",
    fontSize: 14,
    "&:hover": {
      fontWeight: 700
    }
  }),
  menuList: (provided) => ({
    ...provided,
    paddingLeft: 8
  })
};
