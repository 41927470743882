import React from 'react';
import Select, { components } from 'react-select';
import { customSelectStyles } from './customSelectStyles';
import "./select-component.css";
import ArrowDown from "../../assets/arrow-down.svg";

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <img src={ArrowDown} alt="arrow-down" style={{ marginRight: "5px" }} />
  </components.DropdownIndicator>
);

const SelectComponent = (props) => {
  const label = props?.label || "";
  const id = props?.id || "";
  const helperText = props?.helperText || "";
  const options = props?.options || [];
  const onChange = props?.onChange || null;
  const errorMessage = props?.errorMessage || "";
  const value = props?.value || null;

  return (
    <div className="select-wrapper">
      <label htmlFor={id} className="select-label">
        {label}
      </label>
      {helperText ?
        <span className="select-helper-text">{helperText}</span> : null}
      <Select styles={customSelectStyles} placeholder="Select"
              components={{ DropdownIndicator }}
              options={options} value={value} onChange={onChange} />
      <span className="error-message">{errorMessage}</span>
    </div>
  );
};

export default SelectComponent;