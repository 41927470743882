import React from "react";
import Configurator from "./components/configurator/Configurator";

function App () {
  return (
    <>
      <Configurator />
    </>
  );
}

export default App;
