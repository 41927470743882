import React from 'react';
import SelectComponent from '../select-component/SelectComponent';
import dropdownData from '../../data/dropdownData.json';

const FormFields = (props) => {
  const handleSelect = props?.handleSelect || null;
  const errors = props?.errors || null;
  const selected = props?.selected || null;

  return (
    <>
      <SelectComponent label="Select tubing material"
                       id="material-select"
                       options={dropdownData.materials}
                       value={selected.material}
                       onChange={(e) => handleSelect(e, "material")}
                       errorMessage={errors?.material} />
      <div style={{ border: "1px solid #D3D3D3", margin: "1rem 0" }} />
      <SelectComponent label="Select tube dimensions"
                       id="dimensions-select"
                       helperText="Please select the inner dimension of the tubing and the wall thickness"
                       options={dropdownData.dimensions}
                       value={selected.dimensions}
                       onChange={(e) => handleSelect(e, "dimensions")}
                       errorMessage={errors?.dimensions} />
    </>
  );
};

export default FormFields;