import React, { useState } from "react";
import "./configurator.css";
import tubes from "../../data/tubes.json";
import FormFields from '../form-fields/FormFields';
import VideoWrapper from '../video-wrapper/VideoWrapper';

const Configurator = () => {
  const [step, setStep] = useState(1);
  const [selected, setSelected] = useState({
    material: null, dimensions: null,
  });
  const [errors, setErrors] = useState({
    material: null, dimensions: null,
  });
  const [result, setResult] = useState(null);

  const handleSelect = (event, fieldName) => {
    setSelected({ ...selected, [fieldName]: event });
    setErrors({ ...errors, [fieldName]: null });
  };

  const handleResultSetting = () => {
    let isValid = true, _errors = {};

    if (!selected.material) {
      isValid = false;
      _errors.material = "Material is required!";
    }

    if (!selected.dimensions) {
      isValid = false;
      _errors.dimensions = "Dimensions are required!";
    }

    setErrors(_errors);

    if (isValid) {
      setStep(2);
      const _result = tubes?.find(
        (tube) => tube.material === selected.material.value && tube.dimensions === selected.dimensions.value);
      setResult(_result);
    }
  };

  const handleBack = () => {
    setSelected({ material: null, dimensions: null });
    setErrors({ material: null, dimensions: null });
    setResult(null);
    setStep(1);
  };

  return (
    <div className="widget-wrapper">
      <div className="widget-card">
        <div className="widget-card-content">
          {step === 1 ? <FormFields handleSelect={handleSelect}
                                    selected={selected}
                                    errors={errors} /> : <VideoWrapper
            result={result} />
          }
        </div>
      </div>
      <button className="widget-button"
              onClick={step === 1 ? handleResultSetting : handleBack}>
        {step === 1 ? "Next" : "Select again"}
      </button>
    </div>
  );
};

export default Configurator;